@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 14rem;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* NextJS Image Fixes */
.Image > img {
  @apply !relative;
}

/* Contentful Text & Link fixes */
.Typography .richTextHyperlink {
  @apply text-brand-mothernature;
  @apply underline;
}
.Typography:empty {
  display: none;
}

/* Mapbox fixes */
.mapboxgl-popup-content {
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  width: 260px !important;
}

.mapboxgl-popup-tip {
  display: none;
}

.Pagination .disabled {
  display: none !important;
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.full-width {
  width: 100vw !important;
  margin-left: 50% !important;
  margin-right: auto !important;
  transform: translateX(-50%);
  max-width: none !important;
}
.hero-width {
  width: 80rem !important;
  margin-left: 50% !important;
  margin-right: auto !important;
  transform: translateX(-50%);
  @apply max-w-7xl !important;
}

@layer components {
  .VideoContainer {
    video {
      @apply aspect-video h-full w-full object-cover;
    }
  }
  .divider-lines {
    background: url('/images/common/divider-lines.svg') repeat-x left center /
      cover;
    @apply !my-8 h-1.5 w-full border-none;
  }
  .pattern-blur {
    background: linear-gradient(
      90deg,
      rgba(128, 185, 179, 0.25) 0%,
      rgba(227, 210, 230, 0.25) 33%,
      rgba(255, 250, 185, 0.25) 66%,
      rgba(249, 195, 196, 0.25) 100%
    );
  }
  .pattern-blur-inverse {
    background: linear-gradient(
      90deg,
      rgba(249, 195, 196, 0.25) 0%,
      rgba(255, 250, 185, 0.25) 33%,
      rgba(227, 210, 230, 0.25) 66%,
      rgba(128, 185, 179, 0.25) 100%
    );
  }
  .pattern-one {
    @apply full-width h-3 w-full md:h-4;
    background: url('/images/pattern/pattern1.svg') repeat-x left center / cover;
  }
  .heart {
    background: url('/icons/favourite/heart.svg') no-repeat center center /
      contain;

    &.hearted,
    &:hover {
      background: url('/icons/favourite/hearted.svg') no-repeat center center;
    }
  }
  .favourite-badge {
    &:hover {
      .heart {
        background: url('/icons/favourite/hearted.svg') no-repeat center center;
        &:hover {
          @apply opacity-100;
        }
      }
    }
  }
  .icon-pin {
    background: url('/icons/card/pin.svg') no-repeat center center / contain;
    @apply size-5;
  }
  .icon-building {
    background: url('/icons/card/building.svg') no-repeat center center /
      contain;
    @apply size-5;
  }
  .icon-deal {
    background: url('/icons/card/deal.svg') no-repeat center center / contain;
    @apply size-5;
  }
  .carousel {
    &.carousel-hero {
      .carousel-container {
        @apply -mx-10 grid auto-cols-[100%];
      }
      .carousel-slide {
        @apply p-0;
      }
      .carousel-arrow {
        @apply -mt-4 h-8 w-8 md:h-16 md:w-16;
        &.carousel-arrow-next {
          @apply right-2 top-1/2 md:right-8;
        }
        &.carousel-arrow-prev {
          @apply left-2 top-1/2 md:left-8;
        }
        &.carousel-arrow-disabled {
          @apply !hidden;
        }
      }
    }

    .carousel-arrow {
      background: url('/images/common/wonky-circle.svg') no-repeat left top /
        cover;
      &:after {
        @apply h-3 w-2 md:h-5 md:w-3;
        content: '';
      }
      &:hover {
        background: url('/images/common/wonky-circle-light.svg') no-repeat left
          top / cover;
      }

      &.carousel-arrow-next {
        @apply right-2 md:right-0;
        &:after {
          @apply ml-0.5;
          background: url('/icons/common/chevron-right.svg') no-repeat left top;
        }
      }
      &.carousel-arrow-prev {
        @apply right-12 md:right-12;
        &:after {
          @apply -ml-0.5;
          background: url('/icons/common/chevron-left.svg') no-repeat left top;
        }
      }
    }
  }
}

@layer base {
  .nice-transition {
    @apply transition-all delay-150 duration-500;
  }
  .text-shadow {
    text-shadow: 1px 1px 2px #333;
  }
}
